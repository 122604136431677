import { useDispatch, useSelector } from 'react-redux'
import BagSlot from './BagSlot'
import { setBag, setBagTab } from 'redux/professions'
import Box from 'components/layout/elements/Box'
import { Tooltip } from 'react-tippy'
import { useEffect, useState } from 'react'
import { useAccountWideBuffConsumableItem } from 'helpers/consumables'
import { setAuthUser } from 'store'
import Button from '@atoms/Button'
import RaidOverlay from 'components/raids/RaidOverlay'
import Window from 'components/layout/Window'
import { handleError } from 'helpers/raidsTx'
import { toast } from 'react-toastify'
import { convertEssenceFlakes } from 'helpers/professions'
import Crafting from 'components/raids/Crafting'
import CostBox from 'components/layout/elements/CostBox'

export default function Bag ({ handleClose = null, onBagSlotClick = null, enabledItems = null, disabledText = null, selectedBagSlots = null, singleTab = null }) {
  const dispatch = useDispatch()
  const { bag, bagTab } = useSelector(store => store.professions)
  const [confirmUseBagSlot, setConfirmUseBagSlot] = useState(null)
  const [useQuantity, setUseQuantity] = useState(1)
  const [crafting, setCrafting] = useState(null)

  const useConsumable = async () => {
    setCrafting('Using consumable...')
    setConfirmUseBagSlot(null)

    try {
      const data = await useAccountWideBuffConsumableItem({
        userItemId: confirmUseBagSlot.user_item.id,
        quantity: useQuantity
      })
      if (data.bag) {
        dispatch(setBag(data.bag))
      }
      if (data.user) {
        dispatch(setAuthUser(data.user))
        toast.success(`Successfully used ${confirmUseBagSlot.user_item.item.name}`)
      }
    } catch (e) {
      handleError(e, dispatch)
    }
    setCrafting(null)
  }

  const doConvertEssenceFlakes = async () => {
    setCrafting('Converting Essence Flakes...')
    try {
      const data = await convertEssenceFlakes()
      if (data.bag) {
        dispatch(setBag(data.bag))
      }
    } catch (e) {
      handleError(e, dispatch)
    }
    setCrafting(null)
  }

  useEffect(() => {
    if (singleTab) {
      dispatch(setBagTab(singleTab))
    }
  }, [])

  return (
    <>
      {bag && bag[bagTab] && (
        <Box
          className="w-[400px] max-w-full"
          header={
            <>
              {!singleTab && (
                <div className="w-full flex px-1.5 space-x-3">
                  <button
                    type="button"
                    onClick={() => { dispatch(setBagTab('resource')) }}
                    className={bagTab === 'resource' ? 'text-primary' : ''}
                  >
                    Resources
                  </button>
                  <button
                    type="button"
                    onClick={() => { dispatch(setBagTab('consumable')) }}
                    className={bagTab === 'consumable' ? 'text-primary' : ''}
                  >
                    Consumables
                  </button>
                  <button
                    type="button"
                    onClick={() => { dispatch(setBagTab('gear')) }}
                    className={bagTab === 'gear' ? 'text-primary' : ''}
                  >
                    Gear
                  </button>
                  <button
                    type="button"
                    onClick={() => { dispatch(setBagTab('fail_stack')) }}
                    className={bagTab === 'fail_stack' ? 'text-primary' : ''}
                  >
                    Fortune&apos;s Favor
                  </button>
                </div>
              )}
            </>
          }
          actions={
            <div className="ml-auto">
              {bag[bagTab].total_slots - bag[bagTab].free_slots}
              /
              {bag[bagTab].total_slots}
            </div>}
          onClose={handleClose}
        >
          {crafting && <Crafting message={crafting} />}
          <div className='p-1.5'>
            <div className="w-full flex flex-wrap">
              {bag[bagTab].slots.map((bagSlot) => {
                const disabled = enabledItems && !enabledItems.some((bs) => bs.key === bagSlot.key)
                const selected = selectedBagSlots && selectedBagSlots.some((bs) => bs.key === bagSlot.key)

                return (
                  <button
                    type="button"
                    key={bagSlot.key}
                    className={'block p-1.5 w-1/5'}
                    onClick={() => {
                      if (disabled) {
                        return
                      }
                      if (onBagSlotClick) {
                        onBagSlotClick(bagSlot)
                      } else if (bagSlot.user_item.item.type === 'consumable' && bagSlot.user_item.item.consumable_item.usage === 'account-wide-buff') {
                        setUseQuantity(1)
                        setConfirmUseBagSlot(bagSlot)
                      } else if (bagSlot.user_item.item.slug === 'essence-flake') {
                        doConvertEssenceFlakes()
                      }
                    }}
                  >
                    <span className={`w-full rounded bg-tertiary block text-white relative border ${selected ? 'border-primary' : 'border-tertiary'}`}>
                      <BagSlot
                        bagSlot={bagSlot}
                        disabled={disabled}
                      />

                      {disabled && disabledText && (
                        <Tooltip
                          title={disabledText}
                          className="absolute top-0 right-0 bottom-0 left-0"
                        ></Tooltip>
                      )}
                    </span>
                  </button>
                )
              })}
            </div>
          </div>
        </Box>
      )}

      {confirmUseBagSlot && (
      <RaidOverlay>
        <Window
          actions={<>
            <Button
              onClick={() => { setConfirmUseBagSlot(null) }}
            >
              Cancel
            </Button>
            <Button
              onClick={useConsumable}
              className="ml-auto"
              raids
            >
              Use item
            </Button>
          </>}
        >
          <div className="max-w-[340px]">
            <div className="text-center font-semibold text-lg mt-6">
              Are you sure you want to use
              <br/>
              {confirmUseBagSlot.user_item.item.name}
              ?
            </div>
            <div className="p-2 border border-primary rounded mt-8 mb-4">
              {confirmUseBagSlot.user_item.item.description}
            </div>
            <CostBox>
              <div className="text-sm text-raids-text uppercase">
                How many to use?
              </div>
              <div className="ml-auto">
                <input
                  className="py-2 px-3 bg-white rounded border-none text-lg font-bold w-[100px] text-black"
                  type="number"
                  name="quantity"
                  min="1"
                  max={confirmUseBagSlot.user_item.quantity}
                  value={useQuantity}
                  onChange={e => setUseQuantity(e.target.value)}
                />
              </div>
            </CostBox>
          </div>
        </Window>
      </RaidOverlay>
      )}
    </>
  )
}

import { NextSeo } from 'next-seo'
import Nav from 'components/raids/Nav'
import { useSelector } from 'react-redux'
import ErrorPopup from './ErrorPopup'
import WalletOverlay from './WalletOverlay'
import StatBar from './StatBar'
import TabNav from 'components/layout/TabNav'
import { useRouter } from 'next/router'
import Barty from 'components/misc/Barty'
import BagOverlay from 'components/bags/BagOverlay'

export default function Layout ({ children }) {
  const router = useRouter()
  const showWalletOverlay = useSelector(store => store.raids.showWalletOverlay)
  const user = useSelector(store => store.main.auth.user)
  const isLeaderboard = router.asPath.startsWith('/leaderboard')

  return (
    <>
      <NextSeo
        title="Tavern Squad | Raids"
      />

      <div className={'relative flex justify-center w-full h-full'}>
        <div
          id="raid-screen"
          className={'pb-28 lg:overflow-y-auto lg:container lg:h-screen p-4 py-2 lg:pb-4 w-full lg:grow'}
        >
          <div className={'w-full'}>
            {showWalletOverlay && (
              <WalletOverlay />
            )}
            {((isLeaderboard && user) || !isLeaderboard) && (
              <div className="mb-4 lg:pb-2 lg:mb-0">
                <StatBar />
              </div>
            )}
            {children}
            <ErrorPopup />
          </div>

          <div className="hidden fixed bottom-12 right-0 items-center justify-center border-purple-600 pointer-events-none border-triangle border-t-transparent border-l-transparent lg:border-huge lg:bottom-0">
            <div className="fixed bottom-12 right-0 flex items-center justify-center w-4 h-4 text-xs text-black uppercase -translate-x-2 -translate-y-2 -rotate-45 lg:w-12 lg:h-12 lg:text-base lg:bottom-0">
              Beta
            </div>
          </div>

          <Barty />
          <TabNav />
          <Nav />
          <BagOverlay />
        </div>
      </div>
    </>
  )
}

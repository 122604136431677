import axios from 'helpers/api'

export async function applyRaiderConsumableItem ({ raiderId, userItemId }) {
  return await axios.post('/api/consumable-items/use-raider-item', {
    user_item_id: userItemId,
    raider_id: raiderId
  }).then(({ data }) => {
    return data
  })
}

export async function useAccountWideBuffConsumableItem ({ userItemId, quantity }) {
  return await axios.post('/api/consumable-items/use-account-wide-buff-item', {
    user_item_id: userItemId,
    quantity
  }).then(({ data }) => {
    return data
  })
}

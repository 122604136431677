import { getRarityStylesByRarityLabel } from 'consts/rarity'
import { getItemTypeLabel } from 'helpers/items'
import ItemStatPreview from './ItemStatPreview'

export default function ItemTooltip ({ item }) {
  const rarityStyle = getRarityStylesByRarityLabel(item.rarity)
  const isGearItem = item.type === 'gear'

  return (
    <div className="text-left p-3 min-w-[300px] max-w-full">
      <h3 className="font-display text-lg">
        {item.name}
      </h3>
      <div className="flex">
        <div
          className="italic"
          style={rarityStyle.text}
        >
          {item.rarity}
        </div>
        <div className="font-bold ml-auto pl-3 capitalize text-raids-text">
          {item.gear_item && item.gear_item.slot}
          {!isGearItem && getItemTypeLabel(item)}
        </div>
      </div>
      <div className="my-3">
        {!isGearItem && item.description && item.description.length > 0 && (
          <>
            {item.description}
          </>
        )}
        <ItemStatPreview item={item} />
      </div>
      {item.source_label && item.source_label.length > 0 && (
        <div className="italic text-sm text-raids-text">
          {item.source_label}
        </div>
      )}
    </div>
  )
}

import { Tooltip } from 'react-tippy'
import { faTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrystalBall, faGift, faHammerCrash } from '@fortawesome/pro-duotone-svg-icons'

export default function BuffIcon ({ buffs, enemy, positionClasses, small }) {
  const textColor = enemy ? 'text-failure' : 'text-success'

  const getBuffIcon = source => {
    if (source === 'shrines_bonus' || source === 'shrines') {
      return faCrystalBall
    }

    if (source === 'gift') {
      return faGift
    }

    return faHammerCrash
  }

  const getBuffClass = source => {
    if (source === 'shrines_bonus') {
      return 'text-primary'
    }

    return ''
  }

  const tooltip = (
    <div className="text-left">
      <p className="font-bold">
        {enemy ? 'Debuffs' : 'Buffs'}
      </p>

      {buffs.map(buff => (
        <p key={buff.name}>
          {'source' in buff && (
            <FontAwesomeIcon
              icon={getBuffIcon(buff.source)}
              className={`w-3 h-3 mr-0.5 ${getBuffClass(buff.source)}`}
            />
          )}
          {buff.name}
          :
          {' '}
          <span className={textColor}>
            {buff.description}
          </span>
          {buff.charges && (
            <span className="text-primary">
              {' '}
              {buff.charges}
              {' '}
              charge
              {buff.charges > 1 ? 's' : ''}
              {' '}
              left
            </span>
          )}
          {buff.timeRemaining && (
            <span className="text-raids-text">
              {' '}
              (
              {buff.timeRemaining}
              )
            </span>
          )}
        </p>
      ))}
    </div>
  )

  return (
    <div className={`${positionClasses}`}>
      <Tooltip html={tooltip}>
        <div className="cursor-help relative inline-block">
          <FontAwesomeIcon
            icon={faTriangle}
            className={`${small ? 'w-6 h-6' : 'w-8 h-8'} ${textColor}`}
          />

          <span className={`absolute font-display top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center ${small ? 'text-base' : 'text-xl'}`}>
            <span className={buffs.length > 1 ? (small ? 'pb-1' : 'pb-2') : ''}>
              {buffs.length}
            </span>
          </span>
        </div>
      </Tooltip>
    </div>
  )
}

BuffIcon.defaultProps = {
  positionClasses: 'absolute top-0 left-0'
}

import { useDispatch, useSelector } from 'react-redux'
import { setMenu, setMobileMenu } from '../store'
import MobileHeader from './layout/MobileHeader'
import Footer from './layout/Footer'
import { useRouter } from 'next/router'
import RaidsLayout from './raids/Layout'
import DesktopNav from './layout/DesktopNav'
import axios from '../helpers/api'
import { useEffect } from 'react'
import { setParameters } from 'redux/raids'
import Loading from 'icons/Loading'
import TopNav from './layout/TopNav'
import { setConfig, setProfessions } from 'redux/professions'

export default function Layout ({ children }) {
  const parameters = useSelector(store => store.raids.parameters)
  const menuIsOpen = useSelector(state => state.main.menu.isOpen)
  const mobileMenuIsOpen = useSelector(state => state.main.mobileMenu.isOpen)
  const dispatch = useDispatch()
  const router = useRouter()
  const isTavern = (/^\/raids/.test(router.asPath) || /^\/tavern/.test(router.asPath) || /^\/profession/.test(router.asPath) || /^\/marketplace/.test(router.asPath) || /^\/shrines/.test(router.asPath) || /^\/account/.test(router.asPath) || router.asPath.startsWith('/account/adventurers') || router.asPath.startsWith('/leaderboard')) && !router.asPath.startsWith('/shrines-finder') && router.pathname !== '/404'
  const isProfile = /^\/profiles/.test(router.asPath)
  const isHome = router.asPath === '/'
  const needsParameters = router.asPath.startsWith('/raids') || router.asPath.startsWith('/adventurers') || router.asPath.startsWith('/account/adventurers') || router.asPath.startsWith('/tavern')

  useEffect(() => {
    if (parameters) {
      return
    }

    axios.get('/api/raids/parameters').then(({ data }) => {
      dispatch(setParameters(data))
    })
    axios.get('/api/professions').then(({ data }) => {
      dispatch(setProfessions(data))
    })
    axios.get('/api/professions/parameters').then(({ data }) => {
      dispatch(setConfig(data))
    })
  }, [])

  const header = () => {
    return (
      <>
        {!isHome && (
          <DesktopNav
            isTavern={isTavern}
            onToggle={() => dispatch(setMenu(!menuIsOpen))}
          />
        )}

        <MobileHeader
          isTavern={isTavern}
          onToggle={() => dispatch(setMobileMenu(!mobileMenuIsOpen))}
        />
      </>
    )
  }

  const main = () => {
    if (needsParameters && !parameters) {
      return (
        <div className="p-20 flex align-center justify-center">
          <Loading className="w-32 h-32 animate-spin" />
        </div>
      )
    }

    if (isTavern && !isHome) {
      return (
        <main className="relative z-1 h-full w-full">
          <RaidsLayout>
            {children}
          </RaidsLayout>
        </main>
      )
    }

    return (
      <main className="relative z-1 h-full w-full">
        {isHome && <TopNav />}
        {children}
        {!isProfile && footer()}
      </main>
    )
  }

  const footer = () => {
    // eslint-disable-next-line no-constant-condition
    if (isTavern || router.asPath === '/login' || true) {
      return null
    }

    return (
      <>
        <Footer />
      </>
    )
  }

  return (
    <div className="lg:flex lg:h-screen bg-tertiary">
      {header()}
      <div
        id="main-screen"
        className={'lg:grow lg:h-screen lg:overflow-y-auto'}
      >
        {main()}
      </div>
    </div>
  )
}

const colors = {
  mythic: {
    text: {
      color: '#D83110'
    },
    block: {
      backgroundColor: '#D83110'
    }
  },
  legendary: {
    text: {
      color: '#facc15'
    },
    block: {
      backgroundColor: '#facc15'
    }
  },
  epic: {
    text: {
      color: '#9333ea'
    },
    block: {
      backgroundColor: '#9333ea'
    }
  },
  rare: {
    text: {
      color: '#3b82f6'
    },
    block: {
      backgroundColor: '#3b82f6'
    }
  },
  uncommon: {
    text: {
      color: '#22c55e'
    },
    block: {
      backgroundColor: '#22c55e'
    }
  },
  common: {
    text: {
      color: '#94a3b8'
    },
    block: {
      backgroundColor: '#94a3b8'
    }
  },
  basic: {
    text: {
      color: '#F5F5DC'
    },
    block: {
      backgroundColor: '#F5F5DC'
    }
  }
}

const rarity = {
  0.001: {
    name: 'Mythic',
    min: 0.00,
    max: 0.001,
    ...colors.mythic
  },
  0.03: {
    name: 'Legendary',
    min: 0.001,
    max: 0.03,
    ...colors.legendary
  },
  0.08: {
    name: 'Epic',
    min: 0.03,
    max: 0.08,
    ...colors.epic
  },
  0.15: {
    name: 'Rare',
    min: 0.08,
    max: 0.15,
    ...colors.rare
  },
  0.25: {
    name: 'Uncommon',
    min: 0.15,
    max: 0.25,
    ...colors.uncommon
  },
  default: {
    name: 'Common',
    min: 0.25,
    max: 1,
    ...colors.common
  }
}

export default rarity

export function getRarityStyles (chance) {
  for (const boundry of Object.keys(rarity)) {
    if (chance <= boundry) {
      return rarity[boundry]
    }
  }

  return rarity.default
}

export function getRarityStylesByRarityLabel (label) {
  return colors[label.toLowerCase()] ?? colors.common
}

export default function CostBox ({ label = undefined, labelRight = undefined, children }) {
  return (
    <div className="my-2">

      {(label || labelRight) && (
      <div className="flex text-raids-text text-left uppercase text-sm px-2 mb-0.5">
        {label && (
          <div>
            {label}
          </div>
        )}
        {labelRight && (
          <div className="ml-auto">
            {labelRight}
          </div>
        )}
      </div>
      )}
      <div className="flex justify-center items-center border-2 border-tertiary-light rounded p-3 py-2">
        {children}
      </div>
    </div>
  )
}

export default function ItemStatPreview ({ item }) {
  if (!item.stat_preview) {
    return <></>
  }

  const stats = item.stat_preview
  const hasguaranteedEfficiencyStats = !!(stats.efficiency_stats && stats.efficiency_stats.guaranteed && stats.efficiency_stats.guaranteed.length)
  const hasOptionalEfficiencyStats = !!(stats.efficiency_stats && stats.efficiency_stats.optional && stats.efficiency_stats.optional.length)
  const hasguaranteedSecondaryStats = !!(stats.secondary_stats && stats.secondary_stats.guaranteed && stats.secondary_stats.guaranteed.length)
  const hasOptionalSecondaryStats = !!(stats.secondary_stats && stats.secondary_stats.optional && stats.secondary_stats.optional.length)

  return (
    <div className='font-bold text-white'>
      {stats.core_stats && (
        <div>
          +
          {stats.core_stats.value}
          {' '}
          {stats.core_stats.guaranteed.join(' / ')}
        </div>
      )}
      {stats.efficiency_stats && (
        <div>
          +
          {stats.efficiency_stats.value}
          {' '}
          {hasguaranteedEfficiencyStats && stats.efficiency_stats.guaranteed.length > 1 && '('}
          {hasguaranteedEfficiencyStats ? stats.efficiency_stats.guaranteed.join(' or ') : ''}
          {hasguaranteedEfficiencyStats && stats.efficiency_stats.guaranteed.length > 1 && ')'}
          {hasguaranteedEfficiencyStats && hasOptionalEfficiencyStats ? ' and' : ''}
          {' '}
          {hasOptionalEfficiencyStats && stats.efficiency_stats.optional.length > 1 && '['}
          {hasOptionalEfficiencyStats ? stats.efficiency_stats.optional.join(' or ') : ''}
          {hasOptionalEfficiencyStats && stats.efficiency_stats.optional.length > 1 && ']'}
        </div>
      )}
      {stats.secondary_stats && (
        <div>
          +
          {stats.secondary_stats.value}
          {' '}
          {hasguaranteedSecondaryStats && stats.secondary_stats.guaranteed.length > 1 && '('}
          {hasguaranteedSecondaryStats ? stats.secondary_stats.guaranteed.join(' or ') : ''}
          {hasguaranteedSecondaryStats && hasOptionalSecondaryStats ? ' and' : ''}
          {hasguaranteedSecondaryStats && stats.secondary_stats.guaranteed.length > 1 && ')'}
          {' '}
          {hasOptionalSecondaryStats && stats.secondary_stats.optional.length > 1 && '['}
          {hasOptionalSecondaryStats ? stats.secondary_stats.optional.join(' or ') : ''}
          {hasOptionalSecondaryStats && stats.secondary_stats.optional.length > 1 && ']'}
        </div>
      )}
    </div>
  )
}
